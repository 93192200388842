import { combineReducers } from "redux"
import { penderReducer } from "redux-pender"

import ConfigReducer from "./config"
import BranchReducer from "./branch"

export default combineReducers({
  pender: penderReducer,
  ConfigReducer,
  BranchReducer,
})
