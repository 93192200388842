import { createAction } from "redux-actions"
import branchApis from "../apis/branchApis"

// action type
export const SET_INFO = "@trams-logistics-branch/branch/SET_INFO"
export const SET_POUCH_TRACKS = "@trams-logistics-branch/branch/SET_POUCH_TRACKS"
export const SET_POUCH_TRACKS_TOTAL = "@trams-logistics-branch/branch/SET_POUCH_TRACKS_TOTAL"
export const SET_FREIGHT_TRACKS = "@trams-logistics-branch/branch/SET_FREIGHT_TRACKS"
export const SET_FREIGHT_TRACKS_TOTAL = "@trams-logistics-branch/branch/SET_FREIGHT_TRACKS_TOTAL"
export const SET_DRIVERS = "@trams-logistics-branch/branch/SET_DRIVERS"
export const SET_DRIVERS_TOTAL = "@trams-logistics-branch/branch/SET_DRIVERS_TOTAL"
export const INIT_DRIVER = "@trams-logistics-branch/branch/INIT_DRIVER"
export const SET_DRIVER = "@trams-logistics-branch/branch/SET_DRIVER"
export const SET_BOARDS = "@trams-logistics-branch/branch/SET_BOARDS"
export const SET_POPUPS = "@trams-logistics-branch/branch/SET_POPUPS"
// action function
export const setInfo = createAction(SET_INFO)
export const setPouchTracks = createAction(SET_POUCH_TRACKS)
export const setPouchTracksTotal = createAction(SET_POUCH_TRACKS_TOTAL)
export const setFreightTracks = createAction(SET_FREIGHT_TRACKS)
export const setFreightTracksTotal = createAction(SET_FREIGHT_TRACKS_TOTAL)
export const setDrivers = createAction(SET_DRIVERS)
export const setDriversTotal = createAction(SET_DRIVERS_TOTAL)
export const initDriver = createAction(INIT_DRIVER)
export const setDriver = createAction(SET_DRIVER)
export const setBoards = createAction(SET_BOARDS)
export const setPopups = createAction(SET_POPUPS)

// action api function
// 로그인 지사 상세
export const getInfo = (param) => async (dispatch) => {
  const result = await branchApis.getInfo(param)
  if (result.code === 0) {
    dispatch(setInfo(result.data.info))
  } else return false
}
// 행낭 추적 리스트
export const getPouchTracks = (param) => async (dispatch) => {
  const result = await branchApis.getPouchTracks(param)
  if (result.code === 0) {
    dispatch(setPouchTracks(result.data.tracks))
    dispatch(setPouchTracksTotal(result.data.total))
  } else return false
}
// 소화물 추적 리스트
export const getFreightTracks = (param) => async (dispatch) => {
  const result = await branchApis.getFreightTracks(param)
  if (result.code === 0) {
    dispatch(setFreightTracks(result.data.tracks))
    dispatch(setFreightTracksTotal(result.data.total))
    return true
  } else return false
}
// 기사 리스트
export const getDrivers = (param) => async (dispatch) => {
  const result = await branchApis.getDrivers(param)
  if (result.code === 0) {
    dispatch(setDrivers(result.data.drivers))
    dispatch(setDriversTotal(result.data.total))
    return true
  } else return false
}
// 기사 상세
export const getDriver = (param) => async (dispatch) => {
  const result = await branchApis.getDriver(param)
  if (result.code === 0) {
    dispatch(setDriver(result.data.driver))
    return true
  } else return false
}
// 기사 등록
export const postDriver = (param) => async (dispatch) => {
  const result = await branchApis.postDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 기사 수정
export const patchDriver = (param) => async (dispatch) => {
  const result = await branchApis.patchDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 기사 삭제
export const deleteDriver = (param) => async (dispatch) => {
  const result = await branchApis.deleteDriver(param)
  if (result.code === 0) {
    return true
  } else return false
}
// 기사 아이디 중복검사
export const getDriverCheckId = (param) => async (dispatch) => {
  const result = await branchApis.getDriverCheckId(param)
  if (result.code === 0) {
    return result.data.status
  } else return false
}
// 기사 리스트 다운로드
export const getDownloadExcel = (param) => async (dispatch) => {
  const result = await branchApis.getDownloadExcel(param)
  return result
}
// 공지사항 리스트
export const getBoards = (param) => async (dispatch) => {
  const result = await branchApis.getBoards(param)
  if (result.code === 0) {
    dispatch(setBoards(result.data.boards))
    dispatch(setPopups(result.data.boards.map(() => true)))
    return true
  } else return false
}
