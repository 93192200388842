import { handleActions } from "redux-actions"
import actions from "../actions"

// action type load
const {
  SET_INFO,
  SET_POUCH_TRACKS,
  SET_POUCH_TRACKS_TOTAL,
  SET_FREIGHT_TRACKS,
  SET_FREIGHT_TRACKS_TOTAL,
  SET_DRIVERS,
  SET_DRIVERS_TOTAL,
  INIT_DRIVER,
  SET_DRIVER,
  SET_BOARDS,
  SET_POPUPS,
} = actions.BranchAction

// default state value
const initialState = {
  info: {},
  pouchTracks: [],
  pouchTracksTotal: 0,
  freightTracks: [],
  freightTracksTotal: 0,
  drivers: [],
  driver: {},
  boards: [],
  popups: [],
}

// handle action
export default handleActions(
  {
    [SET_INFO]: (state, action) => ({
      ...state,
      info: action.payload,
    }),
    [SET_POUCH_TRACKS]: (state, action) => ({
      ...state,
      pouchTracks: action.payload,
    }),
    [SET_POUCH_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      pouchTracksTotal: action.payload,
    }),
    [SET_FREIGHT_TRACKS]: (state, action) => ({
      ...state,
      freightTracks: action.payload,
    }),
    [SET_FREIGHT_TRACKS_TOTAL]: (state, action) => ({
      ...state,
      freightTracksTotal: action.payload,
    }),
    [SET_DRIVERS]: (state, action) => ({
      ...state,
      drivers: action.payload,
    }),
    [SET_DRIVERS_TOTAL]: (state, action) => ({
      ...state,
      driversTotal: action.payload,
    }),
    [INIT_DRIVER]: (state, action) => ({
      ...state,
      driver: {},
    }),
    [SET_DRIVER]: (state, action) => ({
      ...state,
      driver: action.payload,
    }),
    [SET_BOARDS]: (state, action) => ({
      ...state,
      boards: action.payload,
    }),
    [SET_POPUPS]: (state, action) => ({
      ...state,
      popups: action.payload,
    }),
  },
  initialState
)
