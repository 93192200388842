module.exports = {
  API_URL: process.env.API_URL,
}

// const type = "dev"

// const url = {
//   prod: {
//     // apiURL: "https://api.tramslogistics.tk/app",

//     apiURL: "https://branch-back.행낭의민족.com",
    
//   },
//   dev: {
//     // apiURL: "https://api.tramslogistics.tk/app",

//     apiURL: "http://localhost:3001",
    
//   },
// }
// module.exports = {
//   API_URL: url[type].apiURL,
// }
