import api from "../utils/api"

// 로그인 지사 상세
const getInfo = async () => {
  const result = await api.get(`/info`)
  return result.data
}
// 행낭 추적 리스트
const getPouchTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/pouch/tracks?${query}`)
  return result.data
}
// 소화물 추적 리스트
const getFreightTracks = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.startDate) {
    query += `&startDate=${data.startDate}`
  }
  if (data.endDate) {
    query += `&endDate=${data.endDate}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/freight/tracks?${query}`)
  return result.data
}
// 기사 리스트
const getDrivers = async (data) => {
  let query = ``
  if (data.page !== undefined) {
    query += `page=${data.page}`
  }
  if (data.count) {
    query += `&count=${data.count}`
  }
  if (data.searchType) {
    query += `&searchType=${data.searchType}`
  }
  if (data.search) {
    query += `&search=${data.search}`
  }
  const result = await api.get(`/drivers?${query}`)
  return result.data
}
// 기사 상세
const getDriver = async (data) => {
  const result = await api.get(`/driver?driverSeq=${data.driverSeq}`)
  return result.data
}
// 기사 등록
const postDriver = async (data) => {
  const result = await api.post(`/driver`, { body: data })
  return result.data
}
// 기사 수정
const patchDriver = async (data) => {
  const result = await api.patch(`/driver`, { body: data })
  return result.data
}
// 기사 삭제
const deleteDriver = async (data) => {
  const result = await api.delete(`/driver`, { body: data })
  return result.data
}
// 기사 아이디 중복검사
const getDriverCheckId = async (data) => {
  const result = await api.get(`/driver/check/id?id=${data}`)
  return result.data
}
// 기사 리스트 다운로드
const getDownloadExcel = async (data) => {
  let quary = `?`
  if (data.searchType) {
    quary += `&searchType=${data.searchType}`
  }
  if (data.search) {
    quary += `&search=${data.search}`
  }
  const result = await api.get(`/drivers/download${quary}`, { options: { responseType: "blob" } })
  return result.data
}
// 공지사항 리스트
const getBoards = async (data) => {
  const result = await api.get(`/boards?page=${0}&count=${20}`)
  return result.data
}

export default {
  getInfo,
  getPouchTracks,
  getFreightTracks,
  getDrivers,
  getDriver,
  postDriver,
  patchDriver,
  deleteDriver,
  getDriverCheckId,
  getDownloadExcel,
  getBoards,
}
